<template>
  <v-card elevation="0" class="pa-0 mt-3 mb-4 item-card flex-grow-1">
    <v-alert
      v-if="updatedResState != 0"
      :type="alertInfo.type"
      class="updatedResAlert absolute px-2 py-1"
      dense
      text
      transition="scroll-x-reverse-transition"
      >{{ alertInfo.text }}</v-alert
    >
    <v-container class="pa-0">
      <v-row no-gutters>
        <template v-for="(dataItem, index) in data">
          <v-col :key="dataItem.id" cols="4" v-if="dataItem.text">
            <div class="py-2 pl-3">{{ dataItem.text }}：</div>
          </v-col>
          <v-col
            :key="dataItem.id"
            :cols="
              (index === data.length - 1 ? 6 : dataItem.valueCol || 8) +
              (dataItem.text ? 0 : 4)
            "
          >
            <div
              :class="[
                dataItem.class,
                !dataItem.text ? 'py-2 pl-3' : 'py-2 pr-2',
              ]"
            >
              {{ dataItem.value }}
            </div>
          </v-col>
        </template>

        <v-col cols="5">
          <div class="d-flex pa-1">
            <v-select
              v-model="newData.selectedValue"
              :items="options"
              item-text="name"
              item-value="id"
              label="請選擇司機"
              outlined
              hide-details
              dense
              @change="toUpdateData"
              :key="new Date().getTime()"
            ></v-select>
          </div>
        </v-col>
        <v-col cols="7">
          <div class="d-flex pa-1 relative">
            <datetime-picker
              placeholder="到貨日期"
              v-model="newData.selectedDateValue"
              hide-details
              dense
              :disabledMinDate="minSelectDate"
            ></datetime-picker>
            <!-- <v-btn v-if="newData.selectedDateValue" class="ma-1 defaultValueBtn" absolute text @click="setDefaultDateValue">當日</v-btn> -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
  
<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary",
      validator(value) {
        return ["primary", "secondary", "lighten", "dark", "error"].includes(
          value
        );
      },
    },
    data: {
      type: Array,
      default: () => [{ text: "label", value: "value" }],
    },
    actionText: {
      type: String,
    },
    action: {
      type: Function,
      default: () => {},
    },
    dateValue: {
      type: String,
      default: "",
    },
    minSelectDate: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectOptionValue: {
      type: String,
      default: "",
    },
    updatedResState: {
      type: Number,
      default: 0, // 0: none, 1: success, 2: fail
    },
  },
  data: () => ({
    newData: {
      selectedDateValue: "",
      selectedValue: "",
    },
  }),
  computed: {
    alertInfo() {
      const obj = {
        type: "",
        text: "",
      };
      switch (this.updatedResState) {
        case 1:
          obj.type = "success";
          obj.text = "更新成功";
          break;
        case 2:
          obj.type = "error";
          obj.text = "更新失敗";
          break;
        default:
          obj.type = "";
          obj.text = "";
      }
      return obj;
    },
  },
  methods: {
    setDefaultDateValue() {
      this.newData.selectedDateValue = this.dateValue;
      this.toUpdateData();
    },
    toUpdateData() {
      this.$emit("action", this.newData);
    },
  },
  watch: {
    "newData.selectedDateValue"(newValue, oldValue) {
      if (oldValue !== "") {
        this.toUpdateData();
      }
    },
  },
  mounted() {
    this.newData.selectedValue = this.selectOptionValue;
    this.newData.selectedDateValue = this.dateValue;
  },
};
</script>

<style lang="scss">
.defaultValueBtn {
  right: 0;
  top: 2px;
}

.updatedResAlert {
  top: 2px;
  right: 1px;
}
</style>
  
<style lang="sass">
.linerp-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important
    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0
    .item-card--primary
      .item-card-btn
        background: #FF7D00
        color: #FFFFFF
    .item-card--secondary
      .item-card-btn
        background: #ffffff
        color: #FF7D00
        border: 1px solid #FF7D00
  .provider-store-layout
    .item-card
      background: #ffffff
      color: #122A47
      border: 1px solid #ECECEC !important
      border-radius: 5px !important
      &-btn
        font-size: 14px
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        border-radius: 5px 0 5px 0
    .item-card--primary
      .item-card-btn
        background: #006DE0
        color: #FFFFFF
    .item-card--secondary
      .item-card-btn
        background: #ffffff
        color: #006DE0
        border: 1px solid #006DE0

  .item-card--lighten
    .item-card-btn
      background: #ffffff
      color: #00E086
      border: 1px solid #00E086
  .item-card--dark
    .item-card-btn
      background: #ffffff
      color: #122A47
      border: 1px solid #122A47
  .item-card--error
    .item-card-btn
      background: #ffffff
      color: #E00000
      border: 1px solid #E00000
</style>